






















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Profile, WebsiteLevel } from 'client-website-ts-library/types';
import {
  API,
  Config,
  Logger,
  LogLevel,
} from 'client-website-ts-library/services';
import Masthead from './Common/Masthead.vue';

@Component({
  components: {
    Masthead,
  },
})
export default class SiteMasthead extends Vue {
  @Prop()
  private readonly mini!: boolean;

  @Prop()
  private readonly title!: string;

  @Prop()
  private readonly subtitle!: string;

  @Prop()
  private readonly image!: string;

  @Prop()
  private readonly darken!: boolean;

  @Prop()
  private readonly height!: string;

  private profile: Profile | null = null;

  mounted() {
    this.loadOffice();
  }

  loadOffice(): void {
    switch (Config.Website.Settings!.WebsiteLevel) {
      case WebsiteLevel.Profile:
        API.Profiles.Get(Config.Website.Settings!.WebsiteId, true).then(
          (profile) => {
            this.profile = profile;
          },
        );
        break;
      default:
        Logger.Log(
          LogLevel.Error,
          'Failed to load website. Website level is invalid.',
        );
        break;
    }
  }
}
